import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import Breadcrumb from '../components/Breadcrumb';
import logo from '../assets/logo.png';
import { AppDispatch, RootState } from '../store/store';
import { setLocation } from '../store/slices/locationSlice';
import { setEmail, setCaptcha, sendEmail } from '../store/slices/emailSlice';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

const getContinent = (latitude: any, longitude: any) => {
  if (latitude >= -34 && latitude <= 37 && longitude >= -17 && longitude <= 51) {
    return 'Africa';
  } else if (latitude >= -55 && latitude <= -10 && longitude >= 110 && longitude <= 180) {
    return 'Australia';
  } else if (latitude >= 35 && latitude <= 80 && longitude >= -170 && longitude <= -60) {
    return 'North America';
  } else if (latitude >= -55 && latitude <= 12 && longitude >= -80 && longitude <= -35) {
    return 'South America';
  } else if (latitude >= 50 && latitude <= 75 && longitude >= -20 && longitude <= 60) {
    return 'Europe';
  } else if (latitude >= 10 && latitude <= 55 && longitude >= 60 && longitude <= 180) {
    return 'Asia';
  } else if (latitude >= -55 && latitude <= 0 && longitude >= -70 && longitude <= 30) {
    return 'Antarctica';
  } else {
    return 'Europe';
  }
};

const LandingPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const emailState = useSelector((state: RootState) => state.email);
  const [email, setEmailInput] = useState('');
  const [location, setLocationState] = useState<{ latitude: number; longitude: number } | null>(null);
  const [locationDenied, setLocationDenied] = useState(true);
  const [locationToastShown, setLocationToastShown] = useState(false); 
  const [constraints, setConstraints] = useState<{ allowedGeoRegions: string[] }>({ allowedGeoRegions: [] });

  useEffect(() => {
    fetchConstraints();
    if (!location?.latitude) {
      handleGetLocation();
    }
  }, []);

  const fetchConstraints = async () => {
    try {
      const response = await axios.get('https://pioneer.shaga.xyz:5001/api/constraints');
      setConstraints(response.data);
    } catch (error) {
      toast.error('Failed to fetch constraints');
    }
  };

  const handleGetLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocationState({ latitude, longitude });
          dispatch(setLocation({ latitude, longitude }));
          setLocationDenied(false);

          if (!locationToastShown) {
            setLocationToastShown(true);
            toast.success('Location obtained.');
          }
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            setLocationDenied(true);
            toast.error('We need to capture your location to assess node availability. Please allow location access to proceed.');
          } else {
            toast.error('Failed to obtain location. Please try again.');
          }
        }
      );
    } else {
      toast.error('Geolocation is not supported by this browser');
    }
  };

  const handleSubmit = async () => {
    if (!location) {
      toast.error('Location access is required to proceed.');
      return;
    }

    const continent = getContinent(location.latitude, location.longitude);
    console.log("You are located at ", continent);
    if (constraints.allowedGeoRegions.includes(continent)) {
      if (emailState.captcha) {
        try {
          dispatch(setEmail(email)); 
          await dispatch(sendEmail({ email, lat: location.latitude, long: location.longitude, captcha: emailState.captcha })).unwrap();
          navigate('/otp-validation');
        } catch (error) {
          toast.error('Failed to send OTP');
        }
      } else {
        toast.error('Please complete the CAPTCHA');
      }
    } else {
      try {
        dispatch(setEmail(email));
        await dispatch(sendEmail({ email, lat: location.latitude, long: location.longitude, captcha: emailState.captcha })).unwrap();
        navigate('/nosupport');
      } catch (error) {
        toast.error('Failed to submit your details');
      }
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-cover bg-center" style={{ backgroundImage: `url('/path/to/background.jpg')` }}>
      <div className="absolute top-4 left-4 flex items-center">
        <img src={logo} alt="Logo" className="w-16 h-16" />
        <span className="ml-2 text-2xl font-bold text-black">Shaga Pioneer Program</span>
      </div>
      <Breadcrumb currentStep={0} />
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <h1 className="text-xl font-bold mb-4 text-center">Enter your email</h1>
        <div className="mb-4">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmailInput(e.target.value)}
            className="border p-2 w-full"
          />
        </div>
        {locationDenied && (
          <div className="mb-4 text-red-600 text-sm">
            We require your location to assess node availability. Please enable location services in your browser settings and refresh the page.
          </div>
        )}
        <div className="mb-4 flex justify-center">
          <ReCAPTCHA
            sitekey="6Le-UxcqAAAAAOOitAh_urwgDoMxGajOG6urooBh"
            onChange={(value) => dispatch(setCaptcha(value))}
          />
        </div>
        <button onClick={handleSubmit} className="bg-black text-white rounded-xl p-2 w-full">Submit</button>
      </div>
    </div>
  );
};

export default LandingPage;
