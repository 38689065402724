import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import {store} from './store/store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
      <ToastContainer 
        autoClose={1000} // 1.5 seconds
        position="top-right"
        hideProgressBar
        pauseOnHover={false}
        draggable={false}
        closeButton={false}
        toastClassName="toast-container" // Custom class for general toasts
        bodyClassName="toast-body" // Custom class for toast body
      />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
