import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Breadcrumb from '../components/Breadcrumb';
import Modal from '../components/Modal';
import logo from '../assets/logo.png';
import { AppDispatch, RootState } from '../store/store';
import { setAgreed } from '../store/slices/ndaSlice';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getNDAText } from './ndaText';
import axios from 'axios';

const NDAPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const email = useSelector((state: RootState) => state.email.email);
  const location = useSelector((state: RootState) => state.location); // Assuming you have location in your state
  const [ndaText, setNdaText] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [checked, setChecked] = useState(false);
  const [hasOpenedModal, setHasOpenedModal] = useState(false);
  const [name, setName] = useState('');
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const modalContentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!email || !location) {
      navigate('/');
      return;
    }

    const formattedNdaText = getNDAText(name, `${location.latitude}, ${location.longitude}`, email);
    setNdaText(formattedNdaText);
  }, [email, location, navigate]);

  const handleAgree = async () => {
    if (checked && hasOpenedModal && name.length >= 3 && isScrolledToBottom) {
      dispatch(setAgreed(true));
      await axios.put('https://pioneer.shaga.xyz:5001/api/users/update-name', { email, name });
      toast.success('NDA accepted');
      setTimeout(() => {
        navigate('/schedule');
      }, 1500);
    } else {
      toast.error('Please read and accept the NDA', { className: 'toast-error' });
    }
  };

  const handleOpenModal = () => {
    setShowModal(true);
    setHasOpenedModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    setName(newName);

    // Update NDA text with the new name
    const updatedText = getNDAText(newName, `${location?.latitude}, ${location?.longitude}`, email);
    setNdaText(updatedText);

    // Reset the scroll and acceptance state when the name changes
    setIsScrolledToBottom(false);
    setChecked(false);
  };

  const handleScroll = () => {
    if (modalContentRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = modalContentRef.current;
      if (scrollTop + clientHeight >= scrollHeight-10) {
        setIsScrolledToBottom(true);
      }
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-cover bg-center" style={{ backgroundImage: `url('/path/to/background.jpg')` }}>
      <div className="absolute top-4 left-4 flex items-center">
        <img src={logo} alt="Logo" className="w-16 h-16" />
        <span className="ml-2 text-2xl font-bold text-black">Shaga Pioneer program</span>
      </div>
      <Breadcrumb currentStep={2} />
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <h1 className="text-xl font-bold mb-4 text-center">Non-Disclosure Agreement (NDA)</h1>
        <div className="mb-4">
          <p>Please read and accept the NDA to proceed.</p>
          <button onClick={handleOpenModal} className="text-blue-500 underline mb-4">NDA (Link)</button>
          <label className="block text-gray-700 mb-2">
            <input
              type="checkbox"
              checked={checked}
              onChange={(e) => setChecked(e.target.checked)}
              disabled={!hasOpenedModal || !isScrolledToBottom || name.length < 3}
              className="mr-2"
            />
            I accept NDA
          </label>
        </div>
        <button onClick={handleAgree} className="bg-black text-white rounded-xl p-2 w-full" disabled={!checked}>Next</button>
      </div>
      <Modal show={showModal} onClose={handleCloseModal} title="Non-Disclosure Agreement">
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Enter your name:</label>
          <input
            type="text"
            value={name}
            onChange={handleNameChange}
            className="border p-2 w-full"
            minLength={3}
            placeholder="Your Name"
          />
        </div>
        <div
          ref={modalContentRef}
          onScroll={handleScroll}
          className={`h-48 overflow-y-scroll border p-4 bg-gray-100 ${!name ? 'pointer-events-none opacity-50' : ''}`}
        >
          <div dangerouslySetInnerHTML={{ __html: ndaText }} />
        </div>
        <button
          onClick={handleCloseModal}
          className={`${isScrolledToBottom?'bg-red-600':'bg-red-200'} text-white p-2 w-full mt-4`}
          disabled={!isScrolledToBottom}
        >
          I've read it!
        </button>
      </Modal>
    </div>
  );
};

export default NDAPage;
