import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { Node } from '../../types';

interface NodesState {
  nodes: Node[];
  loading: boolean;
  error: string | null;
}

const initialState: NodesState = {
  nodes: [],
  loading: false,
  error: null,
};

export const fetchNodes = createAsyncThunk('nodes/fetchNodes', async () => {
  const response = await axios.get('https://pioneer.shaga.xyz:5001/api/nodes/list');
  return response.data;
});

export const updateNodeLocation = createAsyncThunk('nodes/updateNodeLocation', async ({ id, location, ShagaNodeID }: { id: string, location: { latitude: number, longitude: number }, ShagaNodeID: string }) => {
  await axios.put(`https://pioneer.shaga.xyz:5001/api/nodes/${id}/location`, { location, ShagaNodeID });
  return { id, location };
});

export const refreshNodes = createAsyncThunk('nodes/refreshNodes', async () => {
  await axios.get('https://pioneer.shaga.xyz:5001/api/nodes/refresh');
  const response = await axios.get('https://pioneer.shaga.xyz:5001/api/nodes/list');
  return response.data;
});

const nodesSlice = createSlice({
  name: 'nodes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNodes.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchNodes.fulfilled, (state, action) => {
        state.loading = false;
        state.nodes = action.payload;
      })
      .addCase(fetchNodes.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateNodeLocation.fulfilled, (state, action) => {
        const index = state.nodes.findIndex(node => node._id === action.payload.id);
        if (index !== -1) {
          state.nodes[index].location = action.payload.location;
        }
      })
      .addCase(refreshNodes.fulfilled, (state, action) => {
        state.nodes = action.payload;
      });
  },
});

export default nodesSlice.reducer;
