import { createSlice } from '@reduxjs/toolkit';

interface NDAState {
  agreed: boolean;
}

const initialState: NDAState = {
  agreed: false,
};

const ndaSlice = createSlice({
  name: 'nda',
  initialState,
  reducers: {
    setAgreed(state, action) {
      state.agreed = action.payload;
    },
  },
});

export const { setAgreed } = ndaSlice.actions;
export default ndaSlice.reducer;
