import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

interface EmailState {
  email: string;
  captcha: string | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: EmailState = {
  email: '',
  captcha: null,
  status: 'idle',
  error: null,
};

export const sendEmail = createAsyncThunk('email/sendEmail', async (data: { email: string; lat: any; long: any; captcha: string | null }) => {
  const response = await axios.post(`${API_BASE_URL}/api/auth/send-email-d`, data);
  return response.data;
});

const emailSlice = createSlice({
  name: 'email',
  initialState,
  reducers: {
    setEmail(state, action) {
      state.email = action.payload;
    },
    setCaptcha(state, action) {
      state.captcha = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendEmail.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(sendEmail.fulfilled, (state) => {
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(sendEmail.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to send email';
      });
  },
});

export const { setEmail, setCaptcha } = emailSlice.actions;
export default emailSlice.reducer;
