import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

interface User {
  username: string;
  role: string;
}

interface LoginState {
  isLoggedIn: boolean;
  user: User | null;
  error: string | null;
}

const initialState: LoginState = {
  isLoggedIn: false,
  user: null,
  error: null,
};

export const login = createAsyncThunk(
  'auth/login',
  async ({ username, password, rememberMe }: { username: string; password: string; rememberMe: boolean }, thunkAPI) => {
    try {
      const response = await axios.post('https://pioneer.shaga.xyz:5001/api/admin/login', { username, password });
      const data = response.data;
      if (rememberMe) {
        localStorage.setItem('user', JSON.stringify(data.user));
      }
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue('Invalid username or password');
    }
  }
);

const loginSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.isLoggedIn = false;
      state.user = null;
      localStorage.removeItem('user');
    },
    checkLocalStorage: (state) => {
      const user = localStorage.getItem('user');
      if (user) {
        state.isLoggedIn = true;
        state.user = JSON.parse(user);
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
      state.error = null;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.error = action.payload as string;
    });
  }
});

export const { logout, checkLocalStorage } = loginSlice.actions;

export default loginSlice.reducer;
