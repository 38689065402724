import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from '../store/store';
import { login, checkLocalStorage } from '../store/slices/loginSlice';
import logo from '../assets/logo.png';

const AdminLogin: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { isLoggedIn, error } = useSelector((state: RootState) => state.auth);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    dispatch(checkLocalStorage());
  }, [dispatch]);

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/shaga-admin/dashboard');
    }
  }, [isLoggedIn, navigate]);

  const handleLogin = async () => {
    await dispatch(login({ username, password, rememberMe }));
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="absolute top-4 left-4 flex items-center">
        <img src={logo} alt="Logo" className="w-16 h-16" />
        <span className="ml-2 text-2xl font-bold text-black">Shaga Pioneer program</span>
      </div>
      <div className="bg-white p-6 rounded-xl shadow-xl shadow-md w-full max-w-sm">
        <center className='text-xl font-bold mb-4'>[ Admin ]</center>
        <div className="mb-4">
          <input
            type="text"
            value={username}
            placeholder="Username"
            onChange={(e) => setUsername(e.target.value)}
            className="border p-2 w-full rounded-lg"
          />
        </div>
        <div className="mb-4">
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="password"
            className="border p-2 w-full rounded-lg"
          />
        </div>
        <div className="mb-4">
          <input
            type="checkbox"
            checked={rememberMe}
            onChange={(e) => setRememberMe(e.target.checked)}
            className="mr-2"
          />
          Remember Me
        </div>
        <button onClick={handleLogin} className="bg-black text-white rounded-xl p-2 w-full">Login</button>
        {error && <p className="text-red-500 mt-4">{error}</p>}
      </div>
    </div>
  );
};

export default AdminLogin;
