import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Breadcrumb from '../components/Breadcrumb';
import logo from '../assets/logo.png';
import { RootState, AppDispatch } from '../store/store';
import { setLocation } from '../store/slices/locationSlice'; // Import the action
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LocationPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const email = useSelector((state: RootState) => state.email.email);
  const [location, setLocationState] = useState<{ latitude: number; longitude: number } | null>(null);

  useEffect(() => {
    // Check if email is empty and redirect if necessary
    if (!email) {
      navigate('/');
    }
  }, [navigate, email]);

  const handleGetLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocationState({ latitude, longitude });
          dispatch(setLocation({ latitude, longitude })); // Store location in Redux
          toast.success('Location obtained');
          setTimeout(() => {
            navigate('/schedule');
          }, 1500); // Delay navigation by 1.5 seconds
        },
        (error) => {
          toast.error('Failed to get location', { className: 'toast-error' });
        }
      );
    } else {
      toast.error('Geolocation is not supported by this browser', { className: 'toast-error' });
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-cover bg-center" style={{ backgroundImage: `url('/path/to/background.jpg')` }}>
      <div className="absolute top-4 left-4 flex items-center">
        <img src={logo} alt="Logo" className="w-16 h-16" />
        <span className="ml-2 text-2xl font-bold text-black">Shaga Pioneer program</span>
      </div>
      <Breadcrumb currentStep={3} />
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <h1 className="text-xl font-bold mb-4 text-center">Access Location</h1>
        <p className="mb-4">Please allow us to access your location to proceed.</p>
        <button onClick={handleGetLocation} className="bg-black text-white rounded-xl p-2 w-full">Share Location</button>
        {location && (
          <div className="mt-4">
            <p>Latitude: {location.latitude}</p>
            <p>Longitude: {location.longitude}</p>
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default LocationPage;
