import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { Metric } from '../../types'; // Import the Metric type

export const fetchMetrics = createAsyncThunk('metrics/fetchMetrics', async (filter: string) => {
    const response = await axios.get(`https://pioneer.shaga.xyz:5001/api/metrics/fetch?filter=${filter}`);
    return response.data;
});

const metricsSlice = createSlice({
  name: 'metrics',
  initialState: {
    data: [] as Metric[],  // Ensure data is an array of Metric
    loading: false,
    error: null as string | null,  // Update the type for error
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMetrics.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMetrics.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchMetrics.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || null;
      });
  },
});

export default metricsSlice.reducer;
