import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

interface OTPState {
  otp: string;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: OTPState = {
  otp: '',
  status: 'idle',
  error: null,
};

export const validateOTP = createAsyncThunk('otp/validateOTP', async (data: { email: string; otp: string }) => {
  const response = await axios.post(`${API_BASE_URL}/api/auth/validate-otp`, data);
  return response.data;
});

const otpSlice = createSlice({
  name: 'otp',
  initialState,
  reducers: {
    setOTP(state, action) {
      state.otp = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(validateOTP.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(validateOTP.fulfilled, (state) => {
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(validateOTP.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to validate OTP';
      });
  },
});

export const { setOTP } = otpSlice.actions;
export default otpSlice.reducer;
