import React from 'react';
import Breadcrumb from '../components/Breadcrumb';
import logo from '../assets/logo.png';

const TesterInstructions: React.FC = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-cover bg-center" style={{ backgroundImage: `url('/path/to/background.jpg')` }}>
      <div className="absolute top-4 left-4 flex items-center">
        <img src={logo} alt="Logo" className="w-16 h-16" />
        <span className="ml-2 text-2xl font-bold text-black">Shaga Pioneer Program</span>
      </div>
      <div className="bg-black p-8 rounded-lg shadow-md w-full text-white mt-20 ">
        <h1 className="text-xl text-white font-bold mb-4 text-center">Experience Shaga!</h1>
        <h2 className="text-xl font-bold mt-4">To enroll for testing</h2><br/>
        <ol className="list-disc list-inside mb-4">
          <li>Visit <a href="https://pioneer.shaga.xyz" className="text-blue-500">https://pioneer.shaga.xyz</a></li>
          <li>Enter your email and validate it</li>
          <li>Please read carefully Shaga NDA and accept the same.</li>
          <li>Schedule your testing slot. Slots are 30 minutes long, with 20-25 minutes of playtime.</li>
          <li>Once your slot is reserved, you will see a thank you screen.</li>
        </ol>
        <p>You will receive an email with details of your reserved slot, including the start time, a link to the APK, and login instructions. The login process for the APK is the same as for the reservation: enter your email and validate with the OTP.</p>
        <p>Please complete the login to the app before your scheduled slot time, as this is a one-time process.</p>
        <h2 className="text-xl font-bold mt-4">During Your Scheduled Slot:</h2><br/>
        <p>If you have scheduled a slot from 3:00 PM to 3:30 PM on Sunday:</p>
        <ol className="list-disc list-inside mb-4">
          <li>You will receive an email notification at 3:00 PM indicating that your slot is active.</li>
          <li>For the best gaming experience, connect your Xbox/PS5 controller to your mobile device. If you don't have a controller, you can use the on-screen controls provided in the app.</li>
          <li>Open the app on your Android device. You will see a game lobby waiting for you to join.</li>
          <li>Click on the lobby to start your gaming session with low latency.</li>
          <li>Your play time ends by 325P</li>
        </ol>
        <p>Note: The quality of your gaming experience depends on your network connection. For the best experience, use a WiFi connection with at least 10 Mbps bandwidth. You can book maximum of 2 slots per day and 5 slots a week currently.</p>
      </div>
    </div>
  );
};

export default TesterInstructions;
