import { configureStore } from '@reduxjs/toolkit';
import emailReducer from './slices/emailSlice';
import otpReducer from './slices/otpSlice';
import ndaReducer from './slices/ndaSlice';
import loginReducer from './slices/loginSlice';
import nodesReducer from './slices/nodesSlice';
import metricsReducer from './slices/metricsSlice';
import locationReducer from './slices/locationSlice';
import usersReducer from './slices/usersSlice';

export const store = configureStore({
  reducer: {
    email: emailReducer,
    otp: otpReducer,
    nda: ndaReducer,
    auth: loginReducer,
    nodes: nodesReducer,
    metrics: metricsReducer,
    location: locationReducer,
    users: usersReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
