import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from '../components/Breadcrumb';
import logo from '../assets/logo.png';
import gameImage from '../assets/game-image.jpg'; // Replace with the actual path to your game image
import gamepadsImage from '../assets/multisupport.png'; // Replace with the actual path to the generated image
import axios from 'axios';
import { RootState } from '../store/store';

const SchedulePage: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedTime, setSelectedTime] = useState('');
  const [availableSlots, setAvailableSlots] = useState<string[]>([]);
  const navigate = useNavigate();
  const email = useSelector((state: RootState) => state.email.email); // Access the email from Redux

  useEffect(() => {
    if (selectedDate) {
      const fetchAvailableSlots = async () => {
        try {
          const response = await axios.get(`https://pioneer.shaga.xyz:5001/api/schedule/available-slots`, {
            params: { date: selectedDate.toISOString(), email: email }
          });

          let slots = response.data.availableSlots;

          // Check if the selected date is today
          const today = new Date();
          const isToday = selectedDate.toDateString() === today.toDateString();

          if (isToday) {
            const currentTime = today.getHours() * 60 + today.getMinutes(); // Current time in minutes from midnight

            // Filter slots to only include times after the current time
            slots = slots.filter( (slot: any) => {
              const [hours, minutes] = slot.split(':').map(Number);
              const slotTime = hours * 60 + minutes; // Slot time in minutes from midnight
              return slotTime > currentTime;
            });
          }

          setAvailableSlots(slots);
        } catch (error) {
          console.error('Error fetching available time slots:', error);
        }
      };

      fetchAvailableSlots();
    }
  }, [selectedDate]);

  const handleSchedule = async () => {
    try {
      const nodeId = 'example-node-id'; // Replace with the actual node ID
      const startTime = selectedDate && selectedTime ? new Date(`${selectedDate.toDateString()} ${selectedTime}`) : null;
      const endTime = startTime ? new Date(startTime.getTime() + 25 * 60000) : null; // 25 minutes later

      if (startTime && endTime) {
        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        await axios.post('https://pioneer.shaga.xyz:5001/api/schedule/reservations', {
          userId: email, // Use the email as the userId
          startTime,
          endTime,
          userTimezone
        });

        navigate('/final');
      } else {
        console.error('Invalid date or time selected');
      }
    } catch (error) {
      console.error('Error scheduling reservation:', error);
    }
  };

  // Function to get the end of the week (Sunday)
  const getEndOfWeek = (date: Date) => {
    const lastDay = new Date(date);
    lastDay.setDate(date.getDate() + (7 - date.getDay()));
    return lastDay;
  };

  // Set min and max dates for the date picker
  const minDate = new Date();
  const maxDate = getEndOfWeek(new Date());

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-cover bg-center" style={{ backgroundImage: `url('/path/to/background.jpg')` }}>
      <div className="absolute top-4 left-4 flex items-center">
        <img src={logo} alt="Logo" className="w-16 h-16" />
        <span className="ml-2 text-2xl font-bold text-black">Shaga Pioneer Program</span>
      </div>
      <Breadcrumb currentStep={3} />
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <h1 className="text-xl font-bold mb-4 text-center">Schedule a Slot</h1>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Select Date</label>
          <DatePicker
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            className="border p-2 w-full"
            minDate={minDate}
            maxDate={maxDate}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Select Time</label>
          <select
            value={selectedTime}
            onChange={(e) => setSelectedTime(e.target.value)}
            className="border p-2 w-full"
          >
            <option value="">Select Time</option>
            {availableSlots.map((time) => (
              <option key={time} value={time}>{time}</option>
            ))}
          </select>
        </div>
        <button onClick={handleSchedule} className="bg-black text-white rounded-xl p-2 w-full">Schedule</button>
      </div>
      <div className="mt-8 text-center flex flex-col items-center md:flex-row md:items-start md:justify-center">
        <div className="mb-4 md:mb-0 md:mr-4">
          <h2 className="text-xl font-bold mb-4">This week on Shaga:</h2>
          <img src={gameImage} alt="Game" className="h-32 max-w-xs rounded-2xl" />
        </div>
        <div className="mb-4 md:mb-0 md:ml-4 mt-2 flex flex-col items-center">
          <h2 className="text-xs font-bold mb-4">Game supports multiple controllers/players</h2>
          <img src={gamepadsImage} alt="Two gamepads connected to a mobile device via Bluetooth" className="h-32 rounded-2xl" />
        </div>
      </div>
    </div>
  );
};

export default SchedulePage;
