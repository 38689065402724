// ndaText.ts

export const getNDAText = (name: string, location: string, email: string, date:string = new Date().toDateString()): string => {
    return `
      <strong>CONFIDENTIALITY AGREEMENT</strong>

      <p>This Confidentiality Agreement (“Agreement”), dated as of <strong>${date}</strong>(“Effective 
        Date”), is between Shaga Labs Inc., A Delaware Corporation with a registered address of 8 
        The Green, Suite A, Dover DE 19901 (“Shaga”), and <strong> ${name} </strong> with email <strong>${email} </strong>, an 
        individual located at <strong>${location}</strong> (“Recipient”)</p>
  
      <p>1. In connection with the evaluation of a potential business arrangement between the Parties (“Purpose”), Shaga may disclose to Recipient, or Recipient may otherwise receive access to, Confidential Information (as defined in Section 2). Recipient shall use the Confidential Information solely for the Purpose and, subject to Section 3, shall not disclose or permit access to Confidential Information other than to its employees, officers, attorneys, and financial advisors (collectively, “Representatives”) who: (a) need to know such Confidential Information for the Purpose; (b) know of the existence and terms of this Agreement; and (c) are bound by written confidentiality agreements no less protective of the Confidential Information than the terms contained herein.Recipient shall safeguard the Confidential Information from unauthorized use, access, or disclosure using at least the degree of care it uses to protect its most sensitive information and no less than a reasonable degree of care. Recipient shall promptly notify Shaga of any unauthorized use or disclosure of Confidential Information and cooperate with Shaga to prevent further use or disclosure. Recipient will be responsible for any breach of this Agreement caused by its Representatives</p>
      
      <p>2. “Confidential Information” means all non-public, proprietary, or confidential information of Shaga related to the Shaga platform, including but not limited to the Shaga Node App and Shaga Mobile App, in oral, visual, written, electronic, or other tangible or intangible form, whether or not marked or designated as “confidential,” and all notes, analyses, summaries, and other materials prepared by Recipient or any of its Representatives that contain, are based on, or otherwise reflect, to any degree, any of the foregoing (“Notes”); provided, however, that Confidential Information does not include any information that: (a) is or becomes generally available to the public other than as a result of Recipient’s or its Representatives’ act or omission; (b) is obtained by Recipient or its Representatives on a non-confidential basis from a third party that was not legally or contractually restricted from disclosing such information; (c) was in Recipient’s or its Representatives’ possession, as established by documentary evidence, before Shaga’s disclosure hereunder; or (d) was or is independently developed by Recipient or its Representatives, as established by documentary evidence, without using any Confidential Information. Confidential Information also includes: (x) the fact that the parties are in discussions regarding the Purpose and that Confidential Information related to the Shaga platform, including the Shaga Node App and Shaga Mobile App, has been disclosed; and (y) any terms, conditions, or arrangements discussed.</p>
    
      <p>(a) Recipient shall ensure that all downloads of the Shaga Node App and Shaga Mobile App are permanently deleted after a successful installation to prevent unauthorized use or distribution. This includes the removal of any installation files, temporary files, or any other data associated with the download process from all devices and storage systems.</p>

      <p>(b) Upon the announcement that the testing period is over, Recipient shall promptly uninstall the Shaga Node App and Shaga Mobile App from all devices and systems within 10 business days. This uninstallation process should be thorough, ensuring that no residual files, data, or traces of the applications remain.</p>

      <p>(c) With each new update released for the Shaga Node App and Shaga Mobile App, the new version shall automatically replace the older version on all devices. The requirement to permanently delete all downloads after a successful installation shall apply to each update, ensuring that only the latest version is in use and all previous versions are fully removed and deleted. This practice is critical for maintaining the security and integrity of the Shaga platform and preventing any potential misuse or unauthorized access to outdated versions.</p>

      <p>3. If Recipient or any of its Representatives is required by applicable law or a valid legal order to disclose any Confidential Information, Recipient shall, before such disclosure, notify Shaga of such requirements so that Shaga may seek a protective order or other remedy, and Recipient shall reasonably assist Shaga therewith. If Recipient remains legally compelled to make such disclosure, it shall: (a) only disclose that portion of the Confidential Information that, in the written opinion of its outside legal counsel, Recipient is required to disclose; and (b) use reasonable efforts to ensure that such Confidential Information is afforded confidential treatment.</p>

      <p>4. On the expiration of this Agreement or otherwise at Shaga’s request, Recipient shall within 3 days, at Shaga’s option, either return to Shaga or destroy/uninstall all Confidential Information in its and its Representatives’ possession other than Notes, and destroy all Notes, and certify in writing to Shaga the destruction of such Confidential Information.</p>

      <p>5. Shaga has no obligation under this Agreement to (a) disclose any Confidential Information or (b) negotiate for, enter into, or otherwise pursue the Purpose. Shaga provides all Confidential Information without any representation or warranty, expressed or implied, as to the accuracy or completeness thereof, and Shaga will have no liability to Recipient or any other person relating to Recipient’s use of any of the Confidential Information or any errors therein or omissions therefrom.</p>

      <p>6. Shaga retains its entire right, title, and interest in and to all Confidential Information, and no disclosure of Confidential Information hereunder will be construed as a license, assignment, or other transfer of any such right, title, and interest to Recipient or any other person. If Recipient provides Shaga with any comments, bug reports, feedback, enhancements, or modifications proposed or suggested by Recipient for any of Shaga’s product/service offerings (“Feedback”), such Feedback is provided on a non-confidential basis (notwithstanding any notice to the contrary Recipient may include in any accompanying communication), and Shaga shall have the right to use such Feedback at its discretion, including, but not limited to the incorporation of such suggested changes into future releases of its offerings. Recipient hereby grants Shaga a perpetual, irrevocable, transferable, sublicensable, nonexclusive license under all rights necessary to incorporate and use the Feedback for any purpose, including to make and sell any products and services.</p>

      <p>7. The rights and obligations of the parties under this Agreement expire 3 years after the Effective Date; provided that with respect to Confidential Information that is a trade secret under the laws of any jurisdiction, such rights and obligations will survive such expiration until, if ever, such Confidential Information loses its trade secret protection other than due to an act or omission of Recipient or its Representatives.</p>

      <p>8. Recipient acknowledges and agrees that any breach of this Agreement will cause injury to Shaga for which money damages would be an inadequate remedy and that, in addition to remedies at law, Shaga is entitled to equitable relief as a remedy for any such breach.</p>

      <p>9. This Agreement and all matters relating hereto are governed by, and construed in accordance with, the laws of the State of Delaware, without regard to the conflict of laws provisions of such State. Each Party irrevocably submits to the exclusive jurisdiction of such courts in any such suit, action, or proceeding.</p>

      <p>10. All notices must be in writing and addressed to the relevant party at its address set out in the preamble (or to such other address such party specifies in accordance with this Section).</p>

      <p>11. This Agreement is the entire agreement of the parties regarding its subject matter and supersedes all prior and contemporaneous understandings, agreements, representations, and warranties, whether written or oral, regarding such subject matter. This Agreement may only be amended, modified, waived, or supplemented by an agreement in writing signed by both parties.</p>

      <p>IN WITNESS WHEREOF, the parties hereto have executed this Agreement as of the Effective Date</p>

    `;
  };
  