import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { checkLocalStorage } from './store/slices/loginSlice';
import AdminLogin from './pages/AdminLogin';
import Dashboard from './pages/Dashboard';
import Nodes from './pages/Nodes';
import Constraints from './pages/Constraints';
import Analytics from './pages/Analytics';
import LandingPage from './pages/LandingPage';
import OTPValidation from './pages/OTPValidation';
import SchedulePage from './pages/SchedulePage';
import FinalScreen from './pages/FinalScreen';
import NDAPage from './pages/NDAPage';
import LocationPage from './pages/LocationPage';
import ProtectedRoute from './components/ProtectedRoute';
import TesterInstructions from './pages/TesterInstructions';
import FailureScreen from './pages/FailureScreen';
import Users from './pages/UsersPage';

const App: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkLocalStorage());
  }, [dispatch]);

  return (
    <Router>
      <div className="flex flex-col min-h-screen bg-gray-200">
        <div className="flex flex-grow">
          <div className={`flex-grow ${'w-full content'}`}>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/info" element={<TesterInstructions />} />
              <Route path="/otp-validation" element={<OTPValidation />} />
              <Route path="/schedule" element={<SchedulePage />} />
              <Route path="/nda" element={<NDAPage />} />
              <Route path="/location" element={<LocationPage />} />
              <Route path="/final" element={<FinalScreen />} />
              <Route path="/nosupport" element={<FailureScreen />} />
              <Route path="/shaga-admin/login" element={<AdminLogin />} />
              {/* <Route
                path="/shaga-admin/dashboard"
                element={<ProtectedRoute element={<Dashboard />} requiredRole="admin" />}
              >
                <Route path="nodes" element={<Nodes />} />
                <Route path="users" element={<Users />} />
                <Route path="constraints" element={<Constraints />} />
                <Route path="analytics" element={<Analytics />} />
              </Route> */}
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
};

export default App;
