import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Breadcrumb from '../components/Breadcrumb';
import logo from '../assets/logo.png';
import { AppDispatch, RootState } from '../store/store';
import { setOTP, validateOTP } from '../store/slices/otpSlice';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const OTPValidation: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const email = useSelector((state: RootState) => state.email.email);
  const [otp, setOTPInput] = useState('');

  useEffect(() => {
    toast.success('Verification code sent in email');
  }, []);

  const handleValidate = async () => {
    console.log('Email:', email); // Debugging: Check if email is being retrieved correctly
    try {
      await dispatch(validateOTP({ email, otp })).unwrap();
      toast.success('Code validated');
      setTimeout(() => {
        navigate('/nda', { replace: true });
      }, 1500); // Delay navigation by 1.5 seconds
    } catch (error) {
      toast.error('Invalid Verification Code', { className: 'toast-error' });
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-cover bg-center" style={{ backgroundImage: `url('/path/to/background.jpg')` }}>
      <div className="absolute top-4 left-4 flex items-center">
        <img src={logo} alt="Logo" className="w-16 h-16" />
        <span className="ml-2 text-2xl font-bold text-black">Shaga Pioneer program</span>
      </div>
      <Breadcrumb currentStep={1} />
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <h1 className="text-xl font-bold mb-4 text-center">Enter Verification Code</h1>
        <div className="mb-4">
          <input
            type="text"
            value={otp}
            onChange={(e) => setOTPInput(e.target.value)}
            className="border p-2 w-full"
          />
        </div>
        <button onClick={handleValidate} className="bg-black text-white rounded-xl  p-2 w-full">Validate Code</button>
      </div>
    </div>
  );
};

export default OTPValidation;
