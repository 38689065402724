import React from 'react';

interface BreadcrumbProps {
  currentStep: number;
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ currentStep }) => {
  const steps = ['Share Location & Enter Email', 'Verify Code', 'Accept NDA', 'Schedule'];

  return (
    <div className="mt-20 flex flex-wrap justify-center items-center mb-8">
      {steps.map((step, index) => (
        <React.Fragment key={index}>
          <div className="flex items-center space-x-2 mb-2 mr-2 ">
            <div className={`flex items-center justify-center text-xs sm:text-sm ml-2 ${index <= currentStep ? 'text-white bg-black' : 'text-gray-500 bg-gray-200'} rounded-full h-8 w-8`}>
              {index + 1}
            </div>
            <span className={`whitespace-nowrap ${index <= currentStep ? 'text-black' : 'text-gray-500'} font-semibold text-xs sm:text-sm`}>
              {step}
            </span>
          </div>
          {index < steps.length - 1 && (
            <div className={`hidden sm:block h-1 ${index < currentStep ? 'bg-black' : 'bg-gray-200'} mx-2`} />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default Breadcrumb;
