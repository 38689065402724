import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Breadcrumb from '../components/Breadcrumb';
import logo from '../assets/logo.svg';
import { AppDispatch, RootState } from '../store/store';
import { setEmail } from '../store/slices/emailSlice';

const FailureScreen: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const email = useSelector((state: RootState) => state.email.email);

  useEffect(() => {
    dispatch(setEmail(''));

    window.history.pushState(null, '', window.location.href);
    window.addEventListener('popstate', () => {
      window.history.pushState(null, '', window.location.href);
    });

    return () => {
      window.removeEventListener('popstate', () => {
        window.history.pushState(null, '', window.location.href);
      });
    };
  }, [dispatch, navigate, email]);

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-cover bg-center" style={{ backgroundImage: `url('/path/to/background.jpg')` }}>
      <div className="bg-black p-8 rounded-lg shadow-md w-full max-w-md shadow-lg flex flex-col items-center">
        <img src={logo} alt="Logo" className="w-32 h-32 mb-4" />
        <h1 className="text-xl font-bold mb-4 text-center text-white">We’re Not There Yet!</h1>
        <p className="text-center text-white">We do not have Shaga Nodes to service your region yet. But we will let you know as soon as your region becomes serviceable.</p>
      </div>
    </div>
  );
};

export default FailureScreen;
