import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

interface User {
  email: string;
  continent: string;
  createdAt: string;
}

interface UserStats {
  [continent: string]: number;
}

interface UsersState {
  data: User[];
  stats: UserStats;
  loading: boolean;
  error: string | null;
}

// Initial state
const initialState: UsersState = {
  data: [],
  stats: {},
  loading: false,
  error: null,
};

// Async thunk to fetch users based on a time period
export const fetchUsers = createAsyncThunk<User[], string>(
  'users/fetchUsers',
  async (timePeriod, { rejectWithValue }) => {
    try {
      const response = await axios.get(`https://pioneer.shaga.xyz:5001/api/users`, {
        params: { period: timePeriod },
      });
      return response.data;
    } catch (error:any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk to fetch user stats grouped by continent
export const fetchUserStats = createAsyncThunk<UserStats>(
  'users/fetchUserStats',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`https://pioneer.shaga.xyz:5001/api/users/stats`);
      return response.data;
    } catch (error:any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Create the slice
const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUsers.fulfilled, (state, action: PayloadAction<User[]>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchUsers.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload?.message || 'Failed to fetch users';
      })
      .addCase(fetchUserStats.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserStats.fulfilled, (state, action: PayloadAction<UserStats>) => {
        state.loading = false;
        state.stats = action.payload;
      })
      .addCase(fetchUserStats.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload?.message || 'Failed to fetch user stats';
      });
  },
});

// Export the reducer
export default usersSlice.reducer;
